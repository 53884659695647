<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('refund_requests')"
		                :isColumns="true">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('refund_request')"
		                      :isColumns="true">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
		        </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()" :export-excel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name" v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname" v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="2" xl="2">
                        <b-form-group :label="$t('reason')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.reason" code="refund_request_reasons"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="5" xl="5">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="5" xl="5">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.student_program_id" :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="2" xl="2">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.status" code="refund_request_statuses"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import RefundRequestService from "@/services/RefundRequestService";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import qs from 'qs'
    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            DatatableFilter,
            Datatable,
            FacultySelectbox,
            ProgramSelectbox,
            ParameterSelectbox,
            AcademicYearsSelectbox
        },
        metaInfo() {
            return {
                title: this.$t('refund_requests')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('detail'),
                                    class: 'ri-search-eye-line',
                                    callback:(row)=>{
                                        this.$router.push('/refund/requests/' + row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id').toUpper(),
                            field: 'id',
                            hidden: false,
                        },
                        {
                            label: this.$t('student_number').toUpper(),
                            field: 'student_number',
                            hidden: false,
                        },
                        {
                            label: this.$t('name').toUpper(),
                            field: 'student_name',
                            hidden: false,
                        },
                        {
                            label: this.$t('surname').toUpper(),
                            field: 'student_surname',
                            hidden: false,
                        },
                        {
                            label: this.$t('program').toUpper(),
                            field: 'program_name',
                            hidden: false,
                        },
                        {
                            label: this.$t('account_holder').toUpper(),
                            field: 'account_holder',
                            hidden: false,
                        },
                        {
                            label: this.$t('bank_name').toUpper(),
                            field: 'bank_name',
                            hidden: false,
                        },
                        {
                            label: this.$t('branch_name').toUpper(),
                            field: 'branch_name',
                            hidden: false,
                        },
                        {
                            label: this.$t('iban').toUpper(),
                            field: 'iban',
                            hidden: false,
                        },
                        {
                            label: this.$t('currency').toUpper(),
                            field: 'currency',
                            hidden: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.$t('date').toUpper(),
                            field: 'created_at',
                            hidden: false,
                        },
                        {
                            label: this.$t('status').toUpper(),
                            field: ('status_name'),
                            hidden: false,
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        //sort: '-id',
                        filter: {},
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        created() {
        },
        methods: {
            callService(config) {
                return RefundRequestService.getAll(config)
            }
        }
    };
</script>

